<template>
  <div class="loginForm">
    <v-card width="600px">
      <v-form @submit.prevent="onSubmit">
        <v-container>
          <v-text-field
            label="Email"
            type="email"
            v-model="email"
            :error-messages="emailError"
            @change="handleEmailChange"
          ></v-text-field>
          <v-text-field
            label="Password"
            type="password"
            v-model="password"
            :error-messages="passwordError"
          ></v-text-field>
        </v-container>
        <BaseButton type="submit" class="-fill-gradient">Submit</BaseButton>
        <router-link :to="{ name: 'RegisterUser' }"
          >Don't have an account? Register.</router-link
        >
        <p v-if="serverError" class="error">{{ serverError }}</p>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { useField, useForm } from 'vee-validate'
import {
  emailValidation,
  passwordValidation,
} from '@/features/FieldValidations'
import { useStore } from '@/store/index'
import { useRouter } from 'vue-router'
import { ref } from 'vue'

export default {
  setup() {
    const validations = {
      email: emailValidation,
      password: passwordValidation,
    }

    const { handleSubmit } = useForm({
      validationSchema: validations,
    })

    const {
      value: email,
      errorMessage: emailError,
      handleChange: handleEmailChange,
    } = useField('email')

    const { value: password, errorMessage: passwordError } =
      useField('password')

    const store = useStore()
    const router = useRouter()

    var serverError = ref('')

    const onSubmit = handleSubmit(() => {
      serverError.value = ''
      const loginUser = { email: email.value, password: password.value }
      store
        .dispatch('user/login', loginUser)
        .then(() => {
          router.push({ name: 'EventList' })
        })
        .catch((err) => {
          console.log(err.response)
          serverError.value = err.response.data.error
        })
    })

    return {
      email,
      emailError,
      password,
      passwordError,
      onSubmit,
      handleEmailChange,
      serverError,
    }
  },
}
</script>

<style scoped>
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  margin-top: 20px;
}

legend {
  font-size: 24px;
  font-weight: 700;
  margin-top: 40px;
  margin-bottom: 20px;
}

.error {
  color: red;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
