import { setAuthorizationDefault } from '@/services/BaseService'
import { Commit } from 'vuex'
import UserService from '@/services/UserService'
import { UserInfo } from '@/types/UserInfo'

export interface UserState {
  userInfo: UserInfo
}

export default {
  namespaced: true,
  state: {
    userInfo: null,
  },
  mutations: {
    SET_USER_DATA(state: UserState, userData: UserInfo) {
      state.userInfo = userData
      setAuthorizationDefault(userData.token)
      localStorage.setItem('user', JSON.stringify(userData))
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem('user')
      location.reload()
    },
  },
  actions: {
    registerUser({ commit }: { commit: Commit }, credentials: UserInfo) {
      return UserService.registerUser(credentials).then(({ data }) => {
        console.log('User data is: ', data)
        commit('SET_USER_DATA', data)
      })
    },
    login({ commit }: { commit: Commit }, credentials: UserInfo) {
      return UserService.login(credentials).then(({ data }) => {
        console.log('User data is: ', data)
        commit('SET_USER_DATA', data)
      })
    },
    logout({ commit }: { commit: Commit }) {
      commit('CLEAR_USER_DATA')
    },
  },
  getters: {
    loggedIn(state: UserState) {
      return !!state.userInfo
    },
  },
}
