<template>
  <div class="home">
    <h1>Welcome!</h1>
    <div v-if="!loggedIn">
      To use this app you'll need to
      <router-link :to="{ name: 'Login' }">Login</router-link>
      or
      <router-link :to="{ name: 'RegisterUser' }">Register</router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('user', ['loggedIn']),
  },
}
</script>

<style></style>
