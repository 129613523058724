import { UserInfo } from '@/types/UserInfo'
import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'http://localhost:3000/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export default {
  registerUser(newUser: UserInfo) {
    return apiClient.post('/register', newUser)
  },

  login(user: UserInfo) {
    return apiClient.post('/login', user)
  },

  setAuthorizationDefault(token: string) {
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  },
}
