<template>
  <v-app>
    <nav-bar />
    <v-main>
      <div id="flashMessage" v-if="GlobalStore.flashMessage">
        {{ GlobalStore.flashMessage }}
      </div>
      <router-view /> </v-main
  ></v-app>
</template>
<script>
import { getApiClient } from '@/services/BaseService'
import NavBar from './components/NavBar.vue'
export default {
  components: { NavBar },
  inject: ['GlobalStore'],
  created() {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('user/SET_USER_DATA', userData)
      this.$router.push({ name: 'EventList' })
    }

    getApiClient().interceptors.response.use(
      (response) => response,
      (error) => {
        console.log(error)
        if (error.response.status === 401) {
          this.$store.dispatch('user/logout')
        }
        return Promise.reject(error)
      }
    )
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /*width: 600px;*/
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  box-sizing: border-box;
  /* padding: 0 20px 20px; */
}

.button,
button {
  align-items: center;
  height: 2em;
  padding: 0 40px;
  background: linear-gradient(to right, #16c0b0, #84cf6a);
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 800;
  cursor: pointer;
  transition: all 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:hover {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
  box-shadow: (0 3px 12px 0 rgba(0, 0, 0, 0.2));
}

h2 {
  font-size: 40px;
  font-weight: 700;
}

h3 {
  font-size: 25px;
  font-weight: 700;
}

h4 {
  font-size: 20px;
  font-weight: 700;
}

button,
input {
  overflow: visible;
}

input[type='text'] {
  padding: 0px 10px;
}

input:focus {
  border-color: #16c0b0;
  outline: 0;
}

@keyframes yellowfade {
  from {
    background: yellow;
  }
  to {
    background: transparent;
  }
}

#flashMessage {
  animation-name: yellowfade;
  animation-duration: 3.5s;
}

button,
label,
input,
optgroup,
select,
textarea {
  display: inline-flex;
  font-family: 'Open Sans', sans-serif;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

label {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 700;
}

input,
textarea {
  box-sizing: border-box;
  border: solid 1px, rgba(0, 0, 0, 0.4);
}

select {
  width: 100%;
  height: 30px;
  padding: 0 24px 0 10px;
  vertical-align: middle;
  background: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.4);
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select:focus {
  border-color: #16c0b0;
  outline: 0;
}

.field {
  margin-bottom: 12px;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
  margin-right: 0.5rem;
}

[type='text'],
[type='number'],
[type='search'],
[type='email'],
[type='password'] {
  height: 30px;
  width: 100%;
  padding: 0 10px;
  font-size: 20px;
}

[type='text']:focus,
[type='number']:focus,
[type='search']:focus,
[type='email']:focus,
[type='password']:focus {
  border-color: #39b982;
}

.errorMessage {
  color: red;
}

input + p.errorMessage {
  margin-bottom: 10px;
  margin-top: 0px;
}

a {
  font-weight: bold;
  color: #2c3e50;
  margin: auto 0.8em auto 0.4em;
  text-decoration: none;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 4em;
  margin-right: 4em;
  max-width: 30em;
  margin-bottom: 2em;
  margin-top: 2em;
}
</style>
