import { getApiClient } from '@/services/BaseService'
import { EventItem } from '@/types/EventItem'
import { AxiosResponse } from 'axios'

export default {
  getEvents(perPage: number, page: number): Promise<AxiosResponse> {
    return getApiClient().get(`/events?_limit=${perPage}&_page=${page}`)
  },
  getEvent(id: string): Promise<AxiosResponse> {
    return getApiClient().get('/events/' + id)
  },
  postEvent(event: EventItem): Promise<AxiosResponse> {
    return getApiClient().post('/events', event)
  },
}
