import { createStore, Store, useStore as baseUseStore } from 'vuex'
import { InjectionKey } from 'vue'
import user from './modules/user'
import event from './modules/event'

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    event,
  },
})

export const key: InjectionKey<Store<any>> = Symbol()
export function useStore() {
  return baseUseStore(key)
}
