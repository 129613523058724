<template>
  <v-btn class="button">
    <slot />
  </v-btn>
</template>

<script>
export default {}
</script>

<style></style>
