<template>
  <div>
    <p>Spaces left: {{ spacesLeft }} out of {{ capacity }}</p>
    <BaseButton @click="increaseCapacity()">Increase Capacity</BaseButton>
    <h2>Attending</h2>
    <ul>
      <li v-for="(name, index) in attending" :key="index">{{ name }}</li>
    </ul>
  </div>
</template>

<script>
import useEventSpace from '@/features/EventSpace'

export default {
  setup() {
    return useEventSpace()
  },
}
</script>

<style></style>
