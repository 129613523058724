
import { defineComponent, PropType } from 'vue'
import { EventItem } from '@/types/EventItem'

export default defineComponent({
  name: 'EventCard',
  props: {
    event: {
      type: Object as PropType<EventItem>,
      required: true,
    },
  },
})
