import { ref, computed } from 'vue'

export default function useEventSpace() {
  const capacity = ref(4)
  const attending = ref(['Tim', 'John', 'Alex'])

  function increaseCapacity() {
    capacity.value++
  }

  const spacesLeft = computed(() => {
    return capacity.value - attending.value.length
  })

  return { capacity, attending, increaseCapacity, spacesLeft }
}
