<template>
  <v-container v-if="event">
    <v-row justify="center">
      <v-col cols="12" sm="6">
        <v-card>
          <div v-if="event.currentEvent">
            <h1>{{ event.currentEvent.title }}</h1>
            <v-container>
              <nav>
                <router-link :to="{ name: 'EventDetails' }"
                  >Details</router-link
                >
                |
                <router-link :to="{ name: 'EventRegister' }"
                  >Register</router-link
                >
                |
                <router-link :to="{ name: 'EventEdit' }">Edit</router-link>
              </nav>
            </v-container>
            <router-view :event="event.currentEvent"></router-view>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: ['id'],
  computed: {
    ...mapState(['event']),
  },
  methods: {
    ...mapActions('event', ['fetchEvent']),
  },
  created() {
    this.fetchEvent(this.id).catch((error) => {
      if (error.response && error.response.status == 404) {
        this.$router.push({
          name: '404Resource',
          params: { resource: 'event' },
        })
      } else {
        this.$router.push({ name: 'NetworkError', params: { error: error } })
      }
    })
  },
}
</script>

<style></style>
