import { number } from 'yup'

export function requiredValidation(value: any) {
  const requiredMessage = 'This field is required'
  if (value === undefined || value === null) return requiredMessage
  if (!String(value).length) return requiredMessage

  return true
}

export function passwordValidation(value: any) {
  const res = requiredValidation(value)
  if (res !== true) return res

  if (String(value).length < 8) return 'Password is too short'
  return true
}

export function minLengthValidation(value: any, minLength: number) {
  if (String(value).length < minLength)
    return 'Please type at least ' + minLength + ' characters.'

  return true
}

export function emailValidation(value: any) {
  const res = requiredValidation(value)
  if (res !== true) return res

  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!regex.test(String(value).toLowerCase())) {
    return 'Please enter a valid email address'
  }

  return true
}

export function noValidation() {
  return true
}
