import EventService from '@/services/EventService'
import { Commit } from 'vuex'
import { EventItem } from '@/types/EventItem'

export interface EventState {
  events: EventItem[]
  currentEvent: EventItem
  totalEvents: number
}

export default {
  namespaced: true,
  state: {
    events: [],
    currentEvent: {},
    totalEvents: 0,
  },
  mutations: {
    ADD_EVENT(state: EventState, event: EventItem) {
      state.events.push(event)
    },
    SET_EVENTS(state: EventState, events: EventItem[]) {
      state.events = events
    },
    SET_TOTAL_EVENTS(state: EventState, totalEvents: number) {
      state.totalEvents = totalEvents
    },
    SET_EVENT(state: EventState, event: EventItem) {
      state.currentEvent = event
    },
  },
  actions: {
    createEvent({ commit }: { commit: Commit }, event: EventItem) {
      return EventService.postEvent(event)
        .then(() => {
          commit('ADD_EVENT', event)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    fetchEvents(
      { commit }: { commit: Commit },
      { pageSize, pageNumber }: { pageSize: number; pageNumber: number }
    ) {
      return new Promise((resolve, reject) => {
        EventService.getEvents(pageSize, pageNumber)
          .then((response) => {
            if (response.data && response.data.events) {
              commit('SET_EVENTS', response.data.events)
            } else {
              commit('SET_EVENTS', response.data)
            }
            commit('SET_TOTAL_EVENTS', response.headers['x-total-count'])
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    fetchEvent(
      { commit, state }: { commit: Commit; state: EventState },
      id: string
    ) {
      return new Promise((resolve, reject) => {
        const existingEvent = state.events.find(
          (event: EventItem) => event.id === id
        )
        if (existingEvent) {
          commit('SET_EVENT', existingEvent)
        } else {
          EventService.getEvent(id)
            .then((response) => {
              commit('SET_EVENT', response.data)
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        }
      })
    },
  },
}
