<template>
  <div>
    <input
      type="checkbox"
      :checked="modelValue"
      class="field"
      @change="$emit('update:modelValue', $event.target.checked)"
      v-bind="$attrs"
      :id="uuid"
    />
    <label :for="uuid">{{ label }}</label>
  </div>
</template>

<script>
import UniqueID from '@/features/UniqueID'

export default {
  props: {
    label: {
      type: String,
      required: true,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uuid = UniqueID().getID()
    return {
      uuid,
    }
  },
}
</script>

<style></style>
