
import { v4 as uuidv4 } from 'uuid'
import { useStore } from '@/store/index'
import { UserState } from '@/store/modules/user'
import { useRouter } from 'vue-router'
/*import {
  minLengthValidation,
  noValidation,
  requiredValidation,
} from '@/features/FieldValidations'*/
import { useForm, useField } from 'vee-validate'
import { ref } from 'vue'
import { object, string, boolean } from 'yup'

export default {
  data() {
    return {
      categories: [
        'sustainability',
        'nature',
        'animal welfare',
        'housing',
        'education',
        'food',
        'community',
      ],
      petOptions: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    }
  },
  setup() {
    /*const validations = {
      category: requiredValidation,
      title: (value) => {
        const res = requiredValidation(value)
        if (res !== true) return res

        const min = minLengthValidation(value, 3)
        return min
      },
      description: requiredValidation,
      location: requiredValidation,
      date: noValidation,
      time: noValidation,
      petsAllowed: noValidation,
      catering: noValidation,
      music: noValidation,
    }*/

    const validations = object({
      category: string().required('You have to select an event category'),
      title: string().min(5, 'Your cool title is too short'),
      description: string().required(),
      location: string().required(),
      date: string(),
      time: string(),
      petsAllowed: boolean().required(),
      catering: boolean(),
      music: boolean(),
    })

    const { handleSubmit, errors } = useForm({
      validationSchema: validations,
      initialValues: {
        category: '',
        petsAllowed: true,
        catering: false,
        music: false,
        title: 'New Event',
        description: '',
        location: '',
        date: '',
        time: '',
      },
    })

    const { value: category } = useField('category')
    const { value: title } = useField('title')
    const { value: description } = useField('description')
    const { value: location } = useField('location')
    const { value: date } = useField('date')
    const { value: time } = useField('time')
    const { value: petsAllowed } = useField('petsAllowed')
    const { value: music } = useField('music')
    const { value: catering } = useField('catering')

    const event = ref({
      category,
      title,
      description,
      location,
      date,
      time,
      petsAllowed,
      extras: ref({ catering, music }),
    })

    const store = useStore()
    const userState = store.state.user as UserState
    const router = useRouter()

    const onSubmit = handleSubmit((values) => {
      console.log('submit', values)
      console.log('event', event.value)

      const newEvent = {
        ...event.value,
        id: uuidv4(),
        organizer: userState.userInfo.name,
      }

      store.dispatch('event/createEvent', newEvent).then(() => {
        router.push({
          name: 'EventDetails',
          params: { id: newEvent.id },
        })
      })
    })

    return { event, errors, onSubmit }
  },
}
