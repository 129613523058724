
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store/index'
import { UserState } from '@/store/modules/user'

export default defineComponent({
  methods: {
    logout() {
      this.store.dispatch('user/logout')
    },
  },
  setup() {
    const store = useStore()
    const loggedIn = computed(() => store.getters['user/loggedIn'])
    const userState = store.state.user as UserState
    return { store, loggedIn, userState }
  },
})
