<template>
  <div>
    <form @submit.prevent="onSubmit">
      <base-input
        label="Email"
        type="email"
        v-model="email"
        name="email"
        :error="errors.email"
      />
      <base-input
        label="Name"
        type="text"
        v-model="name"
        name="name"
        :error="errors.name"
      />
      <base-input
        label="Password"
        type="password"
        v-model="password"
        name="password"
        :error="errors.password"
      />
      <base-button type="submit" name="button">Register</base-button>
      <router-link :to="{ name: 'Login' }"
        >Already have an account? Login.</router-link
      >
      <ul v-if="serverErrors">
        <li v-for="(error, index) in serverErrors" :key="index" class="error">
          {{ error }}
        </li>
      </ul>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useForm, useField } from 'vee-validate'
import { object, string } from 'yup'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
  setup() {
    var validations = object({
      email: string().required().email(),
      name: string().required(),
      password: string().min(8, 'Your password is too short'),
    })

    const { handleSubmit, errors } = useForm({ validationSchema: validations })

    const { value: email } = useField('email')
    const { value: name } = useField('name')
    const { value: password } = useField('password')

    const store = useStore()
    const router = useRouter()

    var serverErrors = ref([])

    const onSubmit = handleSubmit((values) => {
      console.log('submit', values)

      const newUser = {
        name: name.value,
        email: email.value,
        password: password.value,
      }

      store
        .dispatch('user/registerUser', newUser)
        .then(() => {
          router.push({ name: 'EventList' })
        })
        .catch((err) => {
          serverErrors.value = err.response.data.errors
        })
    })

    return { errors, onSubmit, email, name, password, serverErrors }
  },
}
</script>

<style>
.error {
  color: red;
}
</style>
