
// @ is an alias to /src
import EventCard from '@/components/EventCard.vue'
import store from '@/store'
import nprogress from 'nprogress'
import { mapActions } from 'vuex'
import { defineComponent } from 'vue'
import { useStore } from '@/store/index'
import { EventState } from '@/store/modules/event'
import { UserState } from '@/store/modules/user'

export default defineComponent({
  name: 'EventList',
  props: ['page'],
  components: {
    EventCard,
  },
  setup() {
    const store = useStore()
    const userState = store.state.user as UserState
    const eventState = store.state.event as EventState
    return { userState, eventState }
  },
  data() {
    return {
      pageSize: '2',
    }
  },
  computed: {
    hasNextPage() {
      var totalPages = Math.ceil(
        this.eventState.totalEvents / parseInt(this.pageSize)
      )
      return this.page < totalPages
    },
  },
  methods: {
    ...mapActions('event', ['fetchEvents']),
  },
  async created() {
    await store.dispatch('event/fetchEvents', {
      pageSize: parseInt(this.pageSize as string) || 2,
      pageNumber: parseInt(this.page as string) || 1,
    })
  },
  /*beforeRouteEnter(routeTo, routeFrom, next) {
    nprogress.start()
    store
      .dispatch('event/fetchEvents', {
        pageSize: parseInt(routeTo.query.limit) || 2,
        pageNumber: parseInt(routeTo.query.page) || 1,
      })
      .then(() => {
        next()
      })
      .catch((error) => {
        console.log(error)
        next({ name: 'NetworkError', params: { error: error } })
      })
      .finally(() => {
        nprogress.done()
      })
  },*/
  beforeRouteUpdate(routeTo) {
    nprogress.start()
    this.fetchEvents({
      pageSize: parseInt(routeTo.query.limit as string) || 2,
      pageNumber: parseInt(routeTo.query.page as string) || 1,
    })
      .catch((error) => {
        console.log(error)
        return { name: 'NetworkError', params: { error: error } }
      })
      .finally(() => {
        nprogress.done()
      })
  },
})
