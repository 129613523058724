import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'http://localhost:3000/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export function getApiClient() {
  return apiClient
}

export function setAuthorizationDefault(token: string): void {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
}
