<template>
  <p class="errorMessage" :id="id" aria-live="assertive">
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
}
</script>

<style></style>
